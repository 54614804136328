import { Reducer } from 'redux'
import { reducerStateModifySubstore } from 'src/redux/reducers/reducerStateModifySubstore'
import { foodDictionariesInitialState, DICTS_FOOD_GET } from './dicts/store/types'
import { DISH_DELETE } from './dish/store/dishDelete'
import { DISH_GET } from './dish/store/dishGet'
import { DISH_POST } from './dish/store/dishPost'
import { DISH_PUT } from './dish/store/dishPut'
import { DISH_RECIPE_PUT } from './dish/store/dishRecipePut'
import { DISH_INGREDIENT_DELETE, DISH_INGREDIENT_POST, DISH_INGREDIENT_PUT, DISH_VARIANT_DELETE, DISH_VARIANT_POST, DISH_VARIANT_PUT } from './dish/store/IngredientsTypes'
import { CLEAR_FOOD_DISH, foodDishInitialState } from './dish/store/types'
import { MEALS_PLAN_ENTRY_DELETE } from './meals_plan/store/mealsPlanEntryDelete'
import { MEALS_PLAN_ENTRY_POST } from './meals_plan/store/mealsPlanEntryPost'
import { MEALS_PLAN_ENTRY_PUT } from './meals_plan/store/mealsPlanEntryPut'
import { MEALS_PLAN_GET } from './meals_plan/store/mealsPlanGet'
import { MEALS_PLAN_GET_DETAILED } from './meals_plan/store/mealsPlanGetDetailed'
import { MEALS_PLAN_SHOPPING_LIST_GET } from './meals_plan/store/mealsPlanGetShoppingList'
import { MEALS_PLAN_POST } from './meals_plan/store/mealsPlanPost'
import { MEALS_PLAN_PUT } from './meals_plan/store/mealsPlanPut'
import { MEALS_PLANS_GET } from './meals_plan/store/mealsPlansGet'
import { CLEAR_FOOD_MEALS_PLAN } from './meals_plan/store/types'
import { PRODUCT_DELETE } from './product/store/productDelete'
import { PRODUCT_LIST_GET } from './product/store/productListGet'
import { PRODUCT_POST } from './product/store/productPost'
import { PRODUCT_PUT } from './product/store/productPut'
import { CLEAR_FOOD_PRODUCT } from './product/store/types'
import {
    FoodStoreState,
    FoodActionTypes,
} from './types'
import { PRODUCT_CONVERSION_LIST_GET } from './product/conversion/store/productConversionListGet'
import { PRODUCT_CONVERSION_POST } from './product/conversion/store/productConversionPost'
import { PRODUCT_CONVERSION_PUT } from './product/conversion/store/productConversionPut'
import { MEALS_PLAN_DASHBOARD_TODAY_TOMORROW_GET } from './meals_plan/store/mealsPlanDashboardTodayTomorrowGet'
import { DISH_MULTIPLIER_SET } from './dish/store/dishMultiplierSet'
import { MEALS_PLAN_SHOPPING_LIST_LISTONIC_POST } from './meals_plan/store/mealsPlanShoppingListListonicPost'
import { PRODUCT_DISH_LIST_GET } from './product/dish/store/productConversionListGet'
import { TAG_POST } from './tags/store/tagPost'
import { CLEAR_FOOD_TAG } from './tags/store/types'
import { DISHES_GET } from './dishes/store/dishesGet'
import { MEALS_PLAN_GET_MACROS } from './meals_plan/store/mealsPlanGetMacros'
import { MEALS_PLAN_GET_FATSECRET } from './meals_plan/store/mealsPlanGetFatsecret'

export const dishesInitialState: FoodStoreState = {
    dashboard: {},
    dicts: foodDictionariesInitialState,
    dish: foodDishInitialState,
    product: {},
    meals_plan: {},
    tags: {}
}

export const FoodDishActionNames: string[] =
    [DISH_GET, DISH_PUT, DISH_POST, DISH_DELETE, DISH_RECIPE_PUT,
        DISH_VARIANT_POST, DISH_VARIANT_PUT, DISH_VARIANT_DELETE,
        DISH_INGREDIENT_POST, DISH_INGREDIENT_PUT, DISH_INGREDIENT_DELETE,
        CLEAR_FOOD_DISH, DISH_MULTIPLIER_SET]

export const FoodProductActionNames: string[] = [CLEAR_FOOD_PRODUCT,
    PRODUCT_LIST_GET, PRODUCT_POST, PRODUCT_PUT, PRODUCT_DELETE,
    PRODUCT_CONVERSION_LIST_GET, PRODUCT_CONVERSION_POST, PRODUCT_CONVERSION_PUT,
    PRODUCT_DISH_LIST_GET]

export const FoodMealsPlanActionNames: string[] = [CLEAR_FOOD_MEALS_PLAN,
    MEALS_PLANS_GET, MEALS_PLAN_GET, MEALS_PLAN_POST, MEALS_PLAN_PUT,
    MEALS_PLAN_ENTRY_DELETE, MEALS_PLAN_ENTRY_POST, MEALS_PLAN_ENTRY_PUT, MEALS_PLAN_GET_DETAILED,
    MEALS_PLAN_SHOPPING_LIST_GET, MEALS_PLAN_DASHBOARD_TODAY_TOMORROW_GET,
    MEALS_PLAN_SHOPPING_LIST_LISTONIC_POST, MEALS_PLAN_GET_MACROS, MEALS_PLAN_GET_FATSECRET]

export const FoodTagsActionNames: string[] = [TAG_POST, CLEAR_FOOD_TAG]

export const FoodDashboardActionNames: string[] = [DISHES_GET]

const foodReducer: Reducer<FoodStoreState, FoodActionTypes> = (state = dishesInitialState, action: FoodActionTypes) => {

    if (FoodDishActionNames.includes(action.type))
        return reducerStateModifySubstore(state, action, "dish")
    else if (FoodProductActionNames.includes(action.type))
        return reducerStateModifySubstore(state, action, "product")
    else if (FoodMealsPlanActionNames.includes(action.type))
        return reducerStateModifySubstore(state, action, "meals_plan")
    else if (FoodTagsActionNames.includes(action.type))
        return reducerStateModifySubstore(state, action, "tags")
    else if (FoodDashboardActionNames.includes(action.type))
        return reducerStateModifySubstore(state, action, "dashboard")
    else
        switch (action.type) {
            case DICTS_FOOD_GET:
                return reducerStateModifySubstore(state, action, "dicts")
            default:
                return state
        }
}

export default foodReducer

