import { GetObjectStoreFieldsWithParams } from "src/redux/interfaces/GenericStoreApiResults"
import { ApiCallBaseData, callGetDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { FoodStoreState, FoodThunk } from "../../types"



export interface StatusDTO {
    status: string
}

/// MEALS_PLAN_GET_FATSECRET

export const MEALS_PLAN_GET_FATSECRET = 'MEALS_PLAN_GET_FATSECRET'

export interface MealsPlanGetFatsecretParams extends ApiCallBaseData {
    id: number

}

export const mealsPlanGetFatsecret = (params: MealsPlanGetFatsecretParams): FoodThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/food/meals_plan/' + params.id + '/fatsecret'

        return callGetDispatchedApiSameName<StatusDTO, FoodStoreState, void, MealsPlanGetFatsecretParams>(
            apiService, path, dispatch, MEALS_PLAN_GET_FATSECRET, undefined, params);

    }
}

export interface MealsPlanGetFatsecretStoreFields extends GetObjectStoreFieldsWithParams<StatusDTO, MealsPlanGetFatsecretParams> { }

export interface MealsPlanGetFatsecretAction extends MealsPlanGetFatsecretStoreFields {
    type: typeof MEALS_PLAN_GET_FATSECRET
}